<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Mobile Prefix
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings.currentPage * tableSettings.perPage -
              tableSettings.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-mobile-prefix-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="prefix name"
            vid="prefix_name"
            rules="required|max:5"
          >
            <b-form-group>
              <label for="prefix_name">Mobile Prefix</label>
              <b-input
                id="prefix_name"
                v-model="mobilePrefix.prefix_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter mobile prefix"
                autocomplete="off"
                :disabled="state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="mobile network"
            vid="mobile_network"
            rules="required"
          >
            <b-form-group>
              <label for="mobile_network">Mobile Network</label>
              <v-select
                id="cropping_year"
                v-model="selected.mobile_network"
                :options="list.mobile_networks"
                label="network_name"
                placeholder="-- Please Select Network --"
                :disabled="state.busy"
              >
                <template #option="{ network_name }">
                  <span>{{ network_name }}</span>
                </template>
                <template #no-options="">
                  No Available Network
                </template>
              </v-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="mobilePrefix.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminMobilePrefix } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminMobilePrefixes',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Mobile Prefixes'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      list: {
        mobile_networks: [
          { id: 'Globe', network_name: 'Globe' },
          { id: 'Sun', network_name: 'Sun' },
          { id: 'Smart', network_name: 'Smart' },
          { id: 'Dito', network_name: 'Dito' }
        ]
      },
      selected: {
        mobile_network: null
      },
      mobilePrefix: {
        id: '',
        active: 0,
        prefix_name: '',
        mobile_network: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'prefix_name', sortable: true },
          { key: 'mobile_network' },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Mobile Prefix' : 'Add Mobile Prefix'
    }
  },

  watch: {
    'selected.mobile_network' (value) {
      this.mobilePrefix.mobile_network = value?.network_name || null
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminMobilePrefix.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onShowAddForm () {
      this.state.editing = false
      this.mobilePrefix.id = 0
      this.mobilePrefix.prefix_name = ''
      this.mobilePrefix.network_name = ''
      this.mobilePrefix.active = 1
      this.selected.mobile_network = null
      this.$bvModal.show('modal-mobile-prefix-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.mobilePrefix.active = current.active
      this.mobilePrefix.id = current.id
      this.mobilePrefix.prefix_name = current.prefix_name
      this.selected.mobile_network = {
        id: current.mobile_network,
        network_name: current.mobile_network
      }
      this.$bvModal.show('modal-mobile-prefix-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create mobile prefix?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminMobilePrefix.post(this.mobilePrefix).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-mobile-prefix-form')
          this.$swal
            .fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              this.$refs.table.refresh()
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminMobilePrefix.put(this.mobilePrefix).then(({ data }) => {
          this.$bvModal.hide('modal-mobile-prefix-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              const row = _.find(this.$refs.table.localItems, {
                id: data.mobilePrefix.id
              })
              row.prefix_name = data.mobilePrefix.prefix_name
              row.active = data.mobilePrefix.active
              row.updated_at = data.mobilePrefix.updated_at
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    }
  }
}
</script>
